import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Writing`}</h2>
    <ProjectCard title="Generating a new profile picture with AI" link="https://medium.com/@jimmylongley_20386/generating-a-new-linkedin-headshot-with-ai-f1dd9c8cbecf" mdxType="ProjectCard">
  I needed a new headshot but I hate taking photos, so I had Stable Diffusion
  create one for me.
    </ProjectCard>
    <ProjectCard title="What I Wish I Was Told as a Beginner Learning to Code" link="https://developeratlas.com/blog/What-I-Wish-I-Was-Told-as-a-Beginner-Learning-to-Code" mdxType="ProjectCard">
  There's never been a better time to learn how to code. Like most, I idenfity
  as self-taught, and it wasn't easy. Here's what I wish someone told me when I
  was starting out.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      