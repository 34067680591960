import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I am a developer at `}<a parentName="p" {...{
        "href": "https://Consensus.app"
      }}>{`Consensus`}</a>{`, an AI-powered academic search engine dedicated to democratizing access to scientific knowledge.`}</p>
    <p>{`Previously, I led the engineering team at `}<a parentName="p" {...{
        "href": "https://www.booknooklearning.com/"
      }}>{`BookNook`}</a>{`, working to provide equitable literacy education through high-dose tutoring for children.`}</p>
    <p>{`I get inspired by finding ways that technology can help people become better learners.`}</p>
    <p>{`I've designed Amazon delivery drones, traveled the world backpacking, and worked on `}<a parentName="p" {...{
        "href": "https://www.DeveloperAtlas.com/"
      }}>{`Developer Atlas`}</a>{`, a free online resource to guide aspiring programmers toward becoming professional software developers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      